import styles from './styles.module.scss';
import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";

interface RoomTypesProps {
    setRoomType: (roomType: string) => void;
}

const RoomTypes: FC<RoomTypesProps> = ({ setRoomType }) => {
    const [selectedRoomType, setSelectedRoomType] = useState<string>('Standard'); // Устанавливаем стандартный номер по умолчанию
    const { t } = useTranslation();
    const handleRoomSelection = (roomType: string) => {
        setSelectedRoomType(roomType); // Обновляем выбранный тип номера
        setRoomType(roomType); // Вызываем переданный callback
    };

    return (
        <div className={styles.container}>
            <div className={styles.roomTypesHead}>{t("selectNum")}</div>
            <div className={styles.roomTypesList}>
                <button
                    className={`${styles.button} ${selectedRoomType === 'Standard' ? styles.selected : ''}`}
                    onClick={() => handleRoomSelection('Standard')}
                >
                    {t("standard")}
                </button>
                <button
                    className={`${styles.button} ${selectedRoomType === 'Luxe' ? styles.selected : ''}`}
                    onClick={() => handleRoomSelection('Luxe')}
                >
                    {t("lux")}
                </button>
                <button
                    className={`${styles.button} ${selectedRoomType === 'Deluxe' ? styles.selected : ''}`}
                    onClick={() => handleRoomSelection('Deluxe')}
                >
                    {t("delux")}
                </button>
            </div>
        </div>
    );
};

export default RoomTypes;
