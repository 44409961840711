import styles from "./styles.module.scss";
import { FC, useEffect } from "react";
import { numbers } from "../../../../constants/home/numbers";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import HeadChapter from "../../components/HeadChapter/HeadChapter";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ScrollLink from "../../../../components/ScrollLink/ScrollLink";
import { dateForDB } from "../../../../helpers/date-YYYY-MM-DD.helper";
import { useQueryClient } from "@tanstack/react-query";
import { useRoomsDataByMutation } from "../../../../api/queries/rooms/rooms.post";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";


//  "homepage": "https://adil-tarzhemanov.github.io/sevarsoy",

const Numbers: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ref: refNumbers, inView: inViewNumbers } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const roomsData = useAppSelector((state) => state.rangePickerReducer);
  const dispatch = useAppDispatch();

  const roomsDataRequest = {
    date_in: dateForDB(roomsData.dates[0]),
    date_out: dateForDB(roomsData.dates[1]),
    people: roomsData.numbers.map((number: any) => ({
      adults: number.adults,
      childs: number.childs,
    })),
  };
  const client = useQueryClient();
  const { isError, mutate } = useRoomsDataByMutation(
    roomsDataRequest,
    navigate,
    dispatch,
  );

  const onNumber = () => {
    mutate();
    navigate('/api/reservation/check');
  }

  useEffect(() => {
    client.invalidateQueries({ queryKey: ["roomsDetails"] });
  }, []);

  console.log(numbers[0].title)

  return (
    <div className={styles.container} id="numbers">
      <div className={styles.titleWrapper}>
        <HeadChapter title={t("numbers")} color />
      </div>
      <motion.div
        className={styles.numbers}
        ref={refNumbers}
        animate={inViewNumbers && { y: 0, x: 0, opacity: 1 }}
        initial={{ y: -300, x: 0, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        {numbers.map((number) => {
          return (
            <div
              // to="/api/reservation/check"
              // offset={-100}
              // duration={500}
              // key={number.title}
              onClick={onNumber}
              className={styles.listElement}
            >
              <div className={styles.number} key={number.title}>
                <img alt="number" src={number.img} className={styles.numberImg} loading="lazy" />
                <div className={styles.numberDataWrapper}>
                  <h3 className={styles.title}>{number.title}</h3>
                  <h3 className={styles.price}>
                    <span>{number.price}</span>
                  </h3>
                  <p className={styles.description}>
                    {t("meals")}
                    <br />
                    {t("holds")}: {number.capacity} {t("twoOrThreeAdults")}
                    <br />
                    {t("bed")}:{" "}
                    <img
                      src="assets/home/numbers/bed.png"
                      alt="bed"
                      className={styles.bedImg}
                    />{" "}
                    {number.capacity}х
                  </p>
                  {/*<button>Подробнее</button>*/}
                </div>
              </div>
            </div>
          );
        })}
      </motion.div>
    </div>
  );
};

// @ts-ignore
export default Numbers;
